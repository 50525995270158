import {useLocalstorage} from '~/use/localStorage/store'
import type {PriceList} from '~/models/priceList'
import type {PriceListArea} from '~/models/priceListArea'
import {GmModelLibraryModelsContractsTimberPriceListType, TimberPriceListApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";

const { persist } = useLocalstorage()

const state = reactive({
  priceLists: persist<PriceList[]>('priceList', []),
  priceListAreas: persist<PriceListArea[]>('priceListAreas', []),
  lastFetchedPriceLists: persist<number>('lastFetchedPriceLists', 0),
  priceTypes: persist<GmModelLibraryModelsContractsTimberPriceListType[]>('priceTypes', []),
  lastFetchedPriceTypes: persist<number>('lastFetchedPriceTypes', 0),
})

const priceLists = computed(() => state.priceLists)
const priceListAreas = computed(() => state.priceListAreas)
const lastFetchedPriceLists = computed((): number => state.lastFetchedPriceLists)
const priceTypes = computed(() => state.priceTypes)
const lastFetchedPriceTypes = computed((): number => state.lastFetchedPriceTypes)

const clearPriceLists = () => {
  state.priceLists = []
  state.priceListAreas = []
  state.lastFetchedPriceLists = 0
}

const clearPriceTypes = () => {
  state.priceTypes = []
  state.lastFetchedPriceTypes = 0
}

const findById = (id?: string): PriceList | undefined => state.priceLists.find(pl => pl.Id === id)

const setPriceLists = (priceLists: PriceList[]) => {
  state.priceLists = priceLists
}

const setPriceListAreas = (areas: PriceListArea[]) => {
  state.priceListAreas = areas
}

const loadPriceLists = async (force?: boolean) => {
  if (!force &&
      state.priceLists.length &&
      state.priceListAreas.length &&
      lastFetchedPriceLists &&
      lastFetchedPriceLists.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }
  const { axiosClient } = useAxiosClient()
  const timberPriceListApi = new TimberPriceListApi(undefined, '', axiosClient.value)

  try {
    const [priceLists, areas] = await Promise.all([(await timberPriceListApi.timberPriceListGetTimberPriceList()).data, (await timberPriceListApi.timberPriceListGetTimerPriceListsAreaList()).data])
    setPriceLists(priceLists)
    setPriceListAreas(areas)
    state.lastFetchedPriceLists = Date.now()
  } catch (e) {
    console.error('Failed to load price lists', e)
  }
}

const findByPriceListNumber = (listNumber?: string): PriceList | undefined => {
  return state.priceLists.find(pl => pl.PriceListNumber === listNumber)
}

const findPriceListAreasByMunicipalityId = (municipalityId: string): PriceListArea[] => state.priceListAreas.filter(area => area.MunicipalityId === municipalityId)

const loadPriceTypes = async (force?: boolean) => {
  if (!force &&
      state.priceTypes.length &&
      lastFetchedPriceTypes &&
      lastFetchedPriceTypes.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }

  const { axiosClient } = useAxiosClient()
  const timberPriceListApi = new TimberPriceListApi(undefined, '', axiosClient.value)

  try {
    state.priceTypes = (await timberPriceListApi.timberPriceListGetTimberPriceTypes()).data
    state.lastFetchedPriceTypes = Date.now()
  } catch (e) {
    console.error('Failed to load price types', e)
  }
}

export const usePriceListsStore = () => ({
  priceLists,
  priceTypes,
  priceListAreas,
  clearPriceLists,
  clearPriceTypes,
  loadPriceLists,
  loadPriceTypes,
  findById,
  findByPriceListNumber,
  findPriceListAreasByMunicipalityId
})
