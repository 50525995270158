export const CONTRACT_STATES_DRAFT = 1
export const CONTRACT_STATES_AWAITING_SIGNATURE = 961700000
export const CONTRACT_STATES_SIGNED = 961700010
export const CONTRACT_STATES_VH_DRAFT = 961700012
export const CONTRACT_STATES_VH_ACCEPTED = 961700014
export const CONTRACT_STATES_SIGNATURE_REJECTED = 961700021
export const CONTRACT_STATES_BANKID_EXPIRED = 961700022

export const CONTRACT_STATES = [
  {
    Id: CONTRACT_STATES_DRAFT,
    Name: 'STATE_DRAFT'
  },
  {
    Id: CONTRACT_STATES_AWAITING_SIGNATURE,
    Name: 'STATE_AWAITING_SIGNATURE'
  },
  {
    Id: CONTRACT_STATES_SIGNED,
    Name: 'STATE_SIGNED'
  },
  {
    Id: CONTRACT_STATES_VH_DRAFT,
    Name: 'STATE_VH_DRAFT'
  },
  {
    Id: CONTRACT_STATES_VH_ACCEPTED,
    Name: 'STATE_VH_ACCEPTED'
  },
  {
    Id: CONTRACT_STATES_SIGNATURE_REJECTED,
    Name: 'STATE_SIGNATURE_REJECTED'
  },
  {
    Id: CONTRACT_STATES_BANKID_EXPIRED,
    Name: 'STATE_BANKID_EXPIRED'
  }
]

export const CONTRACT_TYPE_FOREST_OPERATION = 1
export const CONTRACT_TYPE_SIVIL_CULTURE = 2
export const CONTRACT_TYPE_REGISTRATION = 3
export const CONTRACT_TYPE_SUPPLY_WOOD = 4

export const CONTRACT_TYPES = [
  {
    Id: CONTRACT_TYPE_FOREST_OPERATION,
    Name: 'TYPE_FOREST_OPERATION',
    Icon: 'spot/large/felling'
  },
  {
    Id: CONTRACT_TYPE_SIVIL_CULTURE,
    Name: 'TYPE_SIVIL_CULTURE',
    Icon: 'spot/large/silviculture'
  },
  {
    Id: CONTRACT_TYPE_REGISTRATION,
    Name: 'TYPE_REGISTRATION',
    Icon: 'spot/large/felling'
  },
  {
    Id: CONTRACT_TYPE_SUPPLY_WOOD,
    Name: 'TYPE_SUPPLY_WOOD',
    Icon: 'spot/large/felling'
  }
]

export const CONTRACT_PERIODS = [
  {
    Id: 1,
    Name: 'PERIOD_WINTER'
  },
  {
    Id: 2,
    Name: 'PERIOD_SPRING'
  },
  {
    Id: 3,
    Name: 'PERIOD_SUMMER'
  },
  {
    Id: 4,
    Name: 'PERIOD_FALL'
  }
]

export const CONTRACT_COMPLETIONS = [
  {
    Id: 1,
    Name: 'COMPLETION_AGREED_VOLUME'
  },
  {
    Id: 2,
    Name: 'COMPLETION_AGREED_AREA'
  }
]
