/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmModelLibraryModelsContractsTimberPriceAreaMappingModel } from '../model';
// @ts-ignore
import { GmModelLibraryModelsContractsTimberPriceListModel } from '../model';
// @ts-ignore
import { GmModelLibraryModelsContractsTimberPriceListType } from '../model';
/**
 * TimberPriceListApi - axios parameter creator
 * @export
 */
export const TimberPriceListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get TimberPriceList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberPriceListGetTimberPriceList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/timberPriceList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all TimberPriceArea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberPriceListGetTimberPriceTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/timberPriceList/timberPriceListTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all TimberPriceArea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberPriceListGetTimerPriceListsAreaList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/timberPriceList/timberPriceListAreas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimberPriceListApi - functional programming interface
 * @export
 */
export const TimberPriceListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimberPriceListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get TimberPriceList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberPriceListGetTimberPriceList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsContractsTimberPriceListModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberPriceListGetTimberPriceList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberPriceListApi.timberPriceListGetTimberPriceList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all TimberPriceArea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberPriceListGetTimberPriceTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsContractsTimberPriceListType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberPriceListGetTimberPriceTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberPriceListApi.timberPriceListGetTimberPriceTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all TimberPriceArea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timberPriceListGetTimerPriceListsAreaList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsContractsTimberPriceAreaMappingModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timberPriceListGetTimerPriceListsAreaList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimberPriceListApi.timberPriceListGetTimerPriceListsAreaList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TimberPriceListApi - factory interface
 * @export
 */
export const TimberPriceListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimberPriceListApiFp(configuration)
    return {
        /**
         * 
         * @summary Get TimberPriceList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberPriceListGetTimberPriceList(options?: any): AxiosPromise<Array<GmModelLibraryModelsContractsTimberPriceListModel>> {
            return localVarFp.timberPriceListGetTimberPriceList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all TimberPriceArea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberPriceListGetTimberPriceTypes(options?: any): AxiosPromise<Array<GmModelLibraryModelsContractsTimberPriceListType>> {
            return localVarFp.timberPriceListGetTimberPriceTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all TimberPriceArea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timberPriceListGetTimerPriceListsAreaList(options?: any): AxiosPromise<Array<GmModelLibraryModelsContractsTimberPriceAreaMappingModel>> {
            return localVarFp.timberPriceListGetTimerPriceListsAreaList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimberPriceListApi - object-oriented interface
 * @export
 * @class TimberPriceListApi
 * @extends {BaseAPI}
 */
export class TimberPriceListApi extends BaseAPI {
    /**
     * 
     * @summary Get TimberPriceList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberPriceListApi
     */
    public timberPriceListGetTimberPriceList(options?: RawAxiosRequestConfig) {
        return TimberPriceListApiFp(this.configuration).timberPriceListGetTimberPriceList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all TimberPriceArea
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberPriceListApi
     */
    public timberPriceListGetTimberPriceTypes(options?: RawAxiosRequestConfig) {
        return TimberPriceListApiFp(this.configuration).timberPriceListGetTimberPriceTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all TimberPriceArea
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimberPriceListApi
     */
    public timberPriceListGetTimerPriceListsAreaList(options?: RawAxiosRequestConfig) {
        return TimberPriceListApiFp(this.configuration).timberPriceListGetTimerPriceListsAreaList(options).then((request) => request(this.axios, this.basePath));
    }
}

