import revive_payload_client_scLHVy1i2M from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DygADdbpYn from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sPAxkH0AMR from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Ee4gFQ8xxP from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_B4RzEmCopk from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zEofldYHeS from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_z0rXmmasgb from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_CjxCigOaUs from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.15.1_@types+node@18.19.70_rollup@4.30.1_typescript@5.7.3_vite@6.0.7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/vsts/work/1/s/.nuxt/pwa-icons-plugin.ts";
import pwa_client_6OITTXZMYb from "/home/vsts/work/1/s/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_rollup@4.30.1_vite@6.0.7_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_RWR41i71zZ from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@8.0.2_rollup@4.30.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _1_application_insights_client_2isRgr4PXf from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_auth_42b9fB0DY4 from "/home/vsts/work/1/s/plugins/2.auth.ts";
import _3_axios_5VOloTgdvi from "/home/vsts/work/1/s/plugins/3.axios.ts";
import error_handler_kEP5FliEXj from "/home/vsts/work/1/s/plugins/error-handler.ts";
import nuxt_link_import_yzIAaKi1HN from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import pwa_client_PLDhtd2phb from "/home/vsts/work/1/s/plugins/pwa.client.ts";
import router_watch_LdArCFqd7Z from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_o4SBtmowK2 from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_pdf_3byIq144W2 from "/home/vsts/work/1/s/plugins/vue-pdf.ts";
import vue_router_append_cjk9JpQRKU from "/home/vsts/work/1/s/plugins/vue-router-append.ts";
import vue_signature_pad_7H3GYOYmnY from "/home/vsts/work/1/s/plugins/vue-signature-pad.ts";
export default [
  revive_payload_client_scLHVy1i2M,
  unhead_DygADdbpYn,
  router_sPAxkH0AMR,
  payload_client_Ee4gFQ8xxP,
  navigation_repaint_client_B4RzEmCopk,
  check_outdated_build_client_zEofldYHeS,
  chunk_reload_client_z0rXmmasgb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CjxCigOaUs,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_6OITTXZMYb,
  i18n_RWR41i71zZ,
  _1_application_insights_client_2isRgr4PXf,
  _2_auth_42b9fB0DY4,
  _3_axios_5VOloTgdvi,
  error_handler_kEP5FliEXj,
  nuxt_link_import_yzIAaKi1HN,
  pwa_client_PLDhtd2phb,
  router_watch_LdArCFqd7Z,
  sentry_logging_o4SBtmowK2,
  vue_pdf_3byIq144W2,
  vue_router_append_cjk9JpQRKU,
  vue_signature_pad_7H3GYOYmnY
]